import { HttpClientModule } from '@angular/common/http';
import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import { AuthenticationService } from './providers/authentication/authentication.service';
import { AuthInterceptor  } from './providers/authentication/auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppDataProvider } from './providers/app-data';
import { WpProvider } from './providers/wp';
import { JobGroupsProvider } from './providers/jobgroups';

import { ComponentsModule } from './components/components.module';
import { PipesModule } from './pipes/pipes.module';
import { PopoverCalendarPageModule } from './pages/popover-calendar/popover-calendar.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';

import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';

import { MaskitoDirective, MaskitoPipe} from '@maskito/angular';
import { AuthService } from './providers/auth.service';

import { AdsenseModule } from 'ng2-adsense';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,

    PipesModule,
    ComponentsModule,
    PopoverCalendarPageModule,

    MaskitoDirective, MaskitoPipe,

    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),

    AdsenseModule.forRoot({
      adClient: 'ca-pub-5297016832074468',
      adSlot: 4272459528,
    }),

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  declarations: [AppComponent],
  providers: [
    InAppBrowser,
    AppDataProvider,
    WpProvider,
    JobGroupsProvider,
    AuthService,
    AuthenticationService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true, },


    provideFirebaseApp(() => initializeApp({
      "projectId":"aba-connect-web",
      "appId":"1:632904659871:web:a1970f0543abfc128103aa",
      "storageBucket":"aba-connect-web.appspot.com",
      "apiKey":"AIzaSyBwFtUs7dhnSI9-pOD2QS5sEvzvUU2ZB8I",
      "authDomain":"aba-connect-web.firebaseapp.com",
      "messagingSenderId":"632904659871"}
    )),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
  ],

  bootstrap: [AppComponent]
})
export class AppModule {}
