<form [formGroup]="form" (ngSubmit)="onSubmit()">

   <ion-list [inset]="true" lines="none">

    <ion-item >
      <ion-button>
        <label for="resume" >Select Resume File (PDF only)&nbsp;</label>
      </ion-button>

      <input style="display:none;" type="file" name="file" id="resume" class="inputfile" (change)="onFileChange($event)"  accept="application/pdf" placeholder="Select Resume (PDF file only)"/>

    </ion-item>


    <ion-text [innerHTML]="resumeFile" style="margin-left: 20px;"></ion-text>


    <ion-item >
      <ion-button type="button" size='small'(click)="clearFile()" class='ion-float-left'>
        Clear
      </ion-button>
      <ion-button type="submit" size='small'[disabled]="!form.controls['resume'].value || loading" class="btn-success ion-margin-left" class='ion-float-left ion-margin-right'>
        <ion-icon name='cloud-upload-outline'></ion-icon>
        &nbsp;Upload
        @if (loading) {
          <i class="fa fa-spinner fa-spin fa-fw"></i>
        }
      </ion-button>
    </ion-item>

  </ion-list>
</form>

