// config constants
export const WORDPRESS_URL = 'https://www.aba-connect.ca/';
//export const WORDPRESS_URL = 'https://dev.aba-connect.ca/';
// export const WORDPRESS_URL = 'https://test.aba-connect.ca/';

export const WORDPRESS_REST_API_URL = WORDPRESS_URL + 'wp-json/wp/v2/';

export const WORDPRESS_ABA_URL = WORDPRESS_URL + 'wp-json/aba/v1/';

export const WORDPRESS_RESUME_URL = WORDPRESS_URL;
